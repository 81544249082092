import { MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';

import { ReactComponent as EditSVG } from '@/assets/icons/edit.svg';

// =================================================================

interface OrderCancelButtonProps {
  id: number;
}

// =================================================================

export const OrderCancelButton = (props: OrderCancelButtonProps) => {
  const { id } = props;

  const navigate = useNavigate();

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      event.stopPropagation();
      navigate(`/orders/${id}`);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  return (
    <Button variant="accent" onClick={handleClick} className="text-white">
      <EditSVG width={28} height={28} />
    </Button>
  );
};
