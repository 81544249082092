import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { MenuCategory } from '@/api/types/menu.types';

const ASPECT_RATIO = 72 / 72;

// =================================================================

interface MenuSlideProps {
  menuCategory: MenuCategory;
}

// =================================================================

export const MenuSlide = memo((props: MenuSlideProps) => {
  const { menuCategory } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const selectMenuCategory = () => {
    setSearchParams(searchParams => {
      searchParams.set('categoryId', `${menuCategory.id}`);
      return searchParams;
    });
  };

  return (
    <button type="button" onClick={selectMenuCategory} className="flex w-full flex-col gap-1">
      <div
        style={{ paddingBottom: `calc(100% / ${ASPECT_RATIO})` }}
        className="relative w-full overflow-hidden rounded-full"
      >
        <img
          src={menuCategory.logo || ''}
          alt="menu-img"
          className="absolute left-0 top-0 h-full w-full object-cover"
        />
      </div>
      <p className="w-full text-center text-gray-500">{menuCategory.name}</p>
    </button>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  MenuSlide.displayName = 'MenuSlide';
}
