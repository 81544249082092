import type { CreateSlice } from '@/contexts/StoreContext/store.types';

export type LockScreenState = {
  isLocked: boolean;
  setLockScreenState: (state: boolean) => void;
};

export type LockScreenStore = {
  lockScreen: LockScreenState;
};

export const createLockScreenSlices: CreateSlice<LockScreenStore> = set => ({
  lockScreen: {
    isLocked: false,
    setLockScreenState: status =>
      set(state => ({ lockScreen: { ...state.lockScreen, isLocked: status } })),
  },
});
