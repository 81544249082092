import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { OrderApi } from '@/api/domains/order.api';

import { OrderQueryKeys } from '@/contexts/QueryContext/query.keys';

export const usePrinterOrderStatistics = () => {
  const [page, setPage] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);

  const { data } = useQuery({
    queryKey: OrderQueryKeys.orderList(page, 30, '', 'all', true),
    queryFn: () =>
      OrderApi.orderList({
        page,
        limit: 30,
        check_printed: true,
      }),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data) {
      const price = data.results.reduce((prev, acc) => prev + acc.price, 0);
      setTotalPrice(prev => prev + price);
      if (page <= data.last_page) {
        setPage(prev => prev + 1);
      }
    }
  }, [data, page]);

  return { totalPrice };
};
