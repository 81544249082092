import { useState, Fragment } from 'react';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { Button } from '@/components/Button';
import { OrderDetailsCard } from '@/components/OrderDetailsCard';
import { PageTitle } from '@/components/PageTitle';

import { PickUpConfirmModal } from '@/pages/pick-up/PickUpConfirmModal';
import { usePickUpMethods } from '@/pages/pick-up/PickUpProvider';

import { ReactComponent as CloseSVG } from '@/assets/icons/close.svg';

export const PickUpConfirmButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { totalPrice, clearMenuList, menus } = usePickUpMethods();

  return (
    <Fragment>
      <div className="fixed right-0 top-0 z-10 h-screen pr-8 pt-14">
        <div className="w-[420px] rounded-2xl border border-gray p-4 shadow-lg">
          {menus.length === 0 && (
            <h3 className="text-center text-2xl font-semibold">
              У вас нет выбранного списка меню!
            </h3>
          )}
          {menus.length > 0 && (
            <Fragment>
              <div className="mb-4 flex items-center justify-between gap-4">
                <PageTitle title={'Собой'} />
                <Button variant="neutral" onClick={clearMenuList}>
                  <CloseSVG />
                </Button>
              </div>
              <OrderDetailsCard className="border-none p-2">
                <table className="w-full">
                  <thead>
                    <tr>
                      <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">
                        №
                      </td>
                      <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">
                        Название
                      </td>
                      <td className="border border-black-100 px-1.5 py-1 text-center text-sm font-semibold">
                        К-во
                      </td>
                      <td className="border border-black-100 px-1.5 py-1 text-right text-sm font-semibold">
                        Цена
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {menus.map((menu, index) => (
                      <tr key={menu.id} className="text-sm">
                        <td className="border border-black-100 px-1.5 py-0.5">{index + 1}</td>
                        <td className="max-w-36 border border-black-100 px-1.5 py-0.5">
                          {menu.title}
                        </td>
                        <td className="border border-black-100 px-1.5 py-0.5 text-center">
                          {menu.count}
                        </td>
                        <td className="border border-black-100 px-1.5 py-0.5 text-right">
                          {thousandSeperator(menu.price)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </OrderDetailsCard>
              <div className="flex items-center justify-between pt-3">
                <OrderDetailsCard.Title>Итого</OrderDetailsCard.Title>
                <OrderDetailsCard.Title>{thousandSeperator(totalPrice)} сум</OrderDetailsCard.Title>
              </div>
              <Button
                variant="primary"
                onClick={() => setIsOpen(true)}
                className="mt-4 h-auto w-full py-4 text-xl text-white"
              >
                Оплатить
              </Button>
            </Fragment>
          )}
        </div>
      </div>
      <PickUpConfirmModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Fragment>
  );
};
