import { useQueryClient } from '@tanstack/react-query';
import { useContext, createContext, useState, type ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { OrderApi } from '@/api/domains/order.api';
import type { ErrorResponse } from '@/api/types/common.types';
import { PaymentTypeEnum } from '@/enums/order.enum';

import { useNotification } from '@/contexts/NotificationContext';
import { OrderQueryKeys } from '@/contexts/QueryContext/query.keys';

// =================================================================

type SubmitData = { price: number; payment_type: PaymentTypeEnum }[];

type PaymentState = {
  onPay: (data: SubmitData) => Promise<void>;
  isLoading: boolean;
  initialValue: number;
};

interface PaymentProviderProps {
  children: ReactNode;
  orderId: string;
  onSuccess: VoidFunction;
  initialValue: number;
}

// =================================================================

const PaymentContext = createContext<PaymentState | null>(null);

// =================================================================

export const PaymentProvider = (props: PaymentProviderProps) => {
  const { orderId, onSuccess, children, initialValue } = props;

  const queryClient = useQueryClient();
  const { notification } = useNotification();

  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const status = searchParams.get('status');
  const search = searchParams.get('search');

  const [isLoading, setIsLoading] = useState(false);

  const onPay = async (data: SubmitData) => {
    setIsLoading(true);

    try {
      await OrderApi.orderPayment({ id: orderId, payments: data });
      if (page) {
        await queryClient.invalidateQueries({
          queryKey: OrderQueryKeys.orderList(Number(page), 15, search || '', status || 'all'),
        });
      }
      onSuccess();
      notification({ type: 'success', message: 'Успешно оплачено!' });
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { statusText } = error.response as ErrorResponse;
      notification({ type: 'error', message: statusText });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PaymentContext.Provider value={{ onPay, isLoading, initialValue }}>
      {children}
    </PaymentContext.Provider>
  );
};

// =================================================================

export const usePaymentMethods = () => {
  const context = useContext(PaymentContext);

  if (!context) {
    throw new Error('usePaymentMethods must be used within a PaymentProvider');
  }

  return context;
};
