import { Fragment, useState, useMemo } from 'react';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';

import { MenuList } from '@/pages/menu/MenuList';
import { MenuFavoriteList } from '@/pages/menu/favorite-list';

const views: Record<number, JSX.Element> = {
  0: <MenuList />,
  1: <MenuFavoriteList />,
};

// =================================================================

export const MenuPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Все',
        onClick: () => {
          setActiveIndex(0);
        },
      },
      {
        label: 'Избранное',
        onClick: () => {
          setActiveIndex(1);
        },
      },
    ];
  }, []);

  return (
    <Fragment>
      <PageTitle title="Меню" className="mb-7" />
      <ul className="mb-5 flex items-center gap-4">
        {tabs.map((item, index) => (
          <li key={index}>
            <Button
              variant={activeIndex === index ? 'primary' : 'neutral'}
              onClick={item.onClick}
              className="border-1 join-item"
            >
              {item.label}
            </Button>
          </li>
        ))}
      </ul>
      {views[activeIndex]}
    </Fragment>
  );
};
