export const TableQueryKeys = {
  PREFIX: 'table',

  categoryList: () => [TableQueryKeys.PREFIX, 'category-list'],
  tableList: (hallId?: string | number) => [
    TableQueryKeys.PREFIX,
    'list',
    hallId ? hallId.toString() : 'all',
  ],
} as const;

// =================================================================

export const OrderQueryKeys = {
  PREFIX: 'order',

  orderList: (
    page: number,
    limit: number,
    search: string = '',
    status: string = 'all',
    checkPrinted: boolean = false,
  ) => [OrderQueryKeys.PREFIX, 'list', limit, page, search, status, checkPrinted],
  orderDetails: (id: number | string) => [OrderQueryKeys.PREFIX, 'details', id.toString()],
} as const;

// =================================================================

export const MenuQueryKeys = {
  PREFIX: 'menu',

  menuCategory: () => [MenuQueryKeys.PREFIX, 'menu-category'],
  menuList: (id?: string | number | null, search?: string | null) => [
    MenuQueryKeys.PREFIX,
    'menu-list',
    id ? id.toString() : 'all',
    search || 'all',
  ],
  favoriteList: () => [MenuQueryKeys.PREFIX, 'favorite-list'],
} as const;

// =================================================================

export const StatisticsQueryKeys = {
  PREFIX: 'statistics',

  daily: (date: string) => [StatisticsQueryKeys.PREFIX, 'daily', date],
  order: (fromDate?: string, toDate?: string) => [
    StatisticsQueryKeys.PREFIX,
    'order',
    fromDate,
    toDate,
  ],
} as const;

// =================================================================

export const ExpensesQueryKeys = {
  PREFIX: 'expenses',

  expensesList: (page: number, limit: number) => [ExpensesQueryKeys.PREFIX, 'list', limit, page],
  categoryList: () => [ExpensesQueryKeys.PREFIX, 'category-list'],
  expensesDetails: (id: string) => [ExpensesQueryKeys.PREFIX, 'details', id],
  waitersList: () => [ExpensesQueryKeys.PREFIX, 'waiters-list'],
} as const;
