import { createContext, useContext, type ReactNode } from 'react';
import { useUnmount } from 'react-use';

import { useStore } from '@/contexts/StoreContext';
import { type PickUpStates } from '@/contexts/StoreContext/slices/pick-up.slices';

// =================================================================

const PickUpContext = createContext<PickUpStates | null>(null);

// =================================================================

export const PickUpProvider = (props: { children: ReactNode }) => {
  const { children } = props;

  const store = useStore(store => store.pickUpStore);

  useUnmount(() => {
    store.clearMenuList();
  });

  return <PickUpContext.Provider value={store}>{children}</PickUpContext.Provider>;
};

// =================================================================

export const usePickUpMethods = () => {
  const context = useContext(PickUpContext);

  if (!context) {
    throw new Error('usePickUpMethods should used within PickUpProvider');
  }

  return context;
};
