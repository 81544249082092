import { MenuStatusEnum } from '@/enums/menu.enum';

import { useFavoriteList } from '@/pages/menu/favorite-list/useFavoriteList';

import { PickUpMenuCard } from '@/pages/pick-up/PickUpMenuCard';

export const FavoriteMenuList = () => {
  const { originData } = useFavoriteList();

  if (!originData) return <h3>Loading...</h3>;

  if (Object.keys(originData.list).length === 0) {
    return <h3 className="mt-8 text-2xl">У вас нет списка избранного!</h3>;
  }

  return (
    <div className="mt-8">
      <ul className="grid gap-4 pr-[460px] lg:grid-cols-2 xl:grid-cols-3">
        {Object.keys(originData.list).map(key => (
          <li key={key}>
            <PickUpMenuCard
              menu={{
                description: '',
                id: originData.list[key].menuId!,
                name: originData.list[key].name,
                price: originData.list[key].price!,
                photos: [''],
                status: MenuStatusEnum.INACTIVE,
                printer: false,
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
