import FocusTrap from 'focus-trap-react';
import { useRef, useCallback } from 'react';

import { twMerge } from 'tailwind-merge';

import { Button } from '@/components/Button';
import { Focusable } from '@/components/Focusable';
import { ModalTitle } from '@/components/Modal/ModalTitle';
import { Overlay, OverlayProps } from '@/components/Overlay';

import { ReactComponent as CloseSVG } from '@/assets/icons/close.svg';

// ----------------------------------------------------------------

export interface ModalProps extends Omit<OverlayProps, 'onOutsideClick'> {
  modalWrapperClassName?: string;
  className?: string;
  shoudlFocusOnMount?: boolean;
  hasCloseButton?: boolean;
  canOutsideClickClose?: boolean;
}

// ----------------------------------------------------------------

export const Modal = (props: ModalProps) => {
  const {
    className,
    modalWrapperClassName,
    children,
    isOpen,
    onClose,
    shoudlFocusOnMount = true,
    hasCloseButton = true,
    canOutsideClickClose = true,
    ...rest
  } = props;

  const nodeRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (canOutsideClickClose) {
        if (nodeRef.current && !nodeRef.current.contains(event.target as Node)) {
          onClose();
        }
      }
    },
    [onClose, canOutsideClickClose],
  );

  return (
    <Overlay isOpen={isOpen} onClose={onClose} onOutsideClick={handleOutsideClick} {...rest}>
      <div
        className={twMerge(
          'absolute left-1/2 top-1/2 w-[calc(100%-2rem)] -translate-x-1/2 -translate-y-1/2',
          modalWrapperClassName,
        )}
      >
        <FocusTrap
          active={isOpen}
          focusTrapOptions={{
            allowOutsideClick: true,
            escapeDeactivates: false,
          }}
        >
          <Focusable
            ref={nodeRef}
            shouldFocusOnMount={shoudlFocusOnMount}
            className={twMerge(
              'modal-box relative m-auto max-h-[95vh] bg-white p-8 shadow-none outline-none',
              className,
            )}
          >
            {hasCloseButton && (
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                className="absolute right-6 top-6 h-auto py-3"
              >
                <CloseSVG />
              </Button>
            )}
            <div tabIndex={-1} className={twMerge(hasCloseButton && 'pt-4')}>
              {children}
            </div>
          </Focusable>
        </FocusTrap>
      </div>
    </Overlay>
  );
};

// =================================================================

Modal.Title = ModalTitle;
