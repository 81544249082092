import { Fragment, useState, useCallback } from 'react';

import { useAuthMethods } from '@/contexts/AuthContext';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modal';

import { ReactComponent as LogoutSVG } from '@/assets/icons/logout.svg';

export const ProfilePage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { logout } = useAuthMethods();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleConfirm = () => {
    try {
      logout();
      handleClose();
    } catch (error) {
      if (!error.response) {
        throw error;
      }
    }
  };

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <h1 className="mb-6 text-4xl font-semibold">Мои данные</h1>
        <Button variant="primary" onClick={handleOpen}>
          <LogoutSVG />
          <span className="text-base">Выйти</span>
        </Button>
      </div>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <h3 className="my-6 text-center text-3xl font-semibold">Вы хотите выйти?</h3>
        <div className="grid grid-cols-2 gap-4">
          <Button variant="neutral" onClick={handleClose} className="w-full">
            Нет
          </Button>
          <Button variant="primary" onClick={handleConfirm} className="w-full">
            Да
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};
