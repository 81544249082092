import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useKey } from 'react-use';

import dayjs from 'dayjs';

import { LOCK_SCREEN_DEFAULT_KEY } from '@/constants/common.constants';

import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

import { useNotification } from '@/contexts/NotificationContext';
import { useStore } from '@/contexts/StoreContext';

import { Button } from '@/components/Button';
import { TextInput } from '@/components/Inputs/TextInput';

import { ReactComponent as LogoSVG } from '@/assets/icons/logo.svg';

// =================================================================

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9] as const;

// =================================================================

export const LockScreen = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isLocked, setLockScreenState } = useStore(store => store.lockScreen);
  const { notification } = useNotification();

  const [value, setValue] = useState('');

  useLockBodyScroll(isLocked);

  const handleClick = (value: string) => {
    inputRef.current?.focus();
    setValue(prev => prev + value);
  };

  const handleSubmit = () => {
    if (value === '1234') {
      setLockScreenState(false);
      localStorage.removeItem(LOCK_SCREEN_DEFAULT_KEY);
    } else {
      notification({
        type: 'error',
        message: 'Неверный пароль!',
      });
    }
  };

  useKey(event => event.key === 'Enter', handleSubmit);

  return (
    <div className="bg- fixed inset-0 z-[999] bg-slate-100">
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="absolute bottom-6 right-6">
          <Timer />
        </div>
        <div className="card mx-6 w-full border bg-base-100 shadow-xl lg:w-1/2 xl:w-1/4">
          <figure className="my-6">
            <LogoSVG />
          </figure>
          <div className="card-body">
            <TextInput
              ref={inputRef}
              autoFocus
              value={value}
              onChange={event => setValue(event.target.value)}
              type="password"
              placeholder="Введите пароль"
              wrapperClassName="mb-6 h-auto"
              className="h-auto py-5 text-center text-3xl font-semibold"
            />
            <div className="mb-3 grid grid-cols-3 gap-4">
              {numbers.map(number => (
                <Button
                  key={number}
                  onClick={() => handleClick(`${number}`)}
                  className="kbd kbd-lg h-auto bg-slate-100 py-5 text-3xl text-slate-800 shadow-lg hover:bg-slate-100"
                >
                  {number}
                </Button>
              ))}
            </div>
            <div className="grid grid-cols-[1fr_2fr] gap-4">
              <Button
                onClick={() => handleClick('0')}
                className="kbd kbd-lg h-auto bg-slate-100 py-5 text-3xl text-slate-800 shadow-lg hover:bg-slate-100"
              >
                0
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                className="kbd kbd-lg h-auto bg-slate-100 py-5 text-3xl text-slate-800 shadow-lg hover:bg-slate-100"
              >
                Enter
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// =================================================================

const Timer = () => {
  const navigate = useNavigate();

  const [time, setTime] = useState({
    hours: dayjs().format('HH'),
    minutes: dayjs().format('mm'),
    seconds: dayjs().format('ss'),
  });

  useEffectOnce(() => {
    navigate('/orders', { replace: true });

    const timer = setInterval(() => {
      setTime({
        hours: dayjs().format('HH'),
        minutes: dayjs().format('mm'),
        seconds: dayjs().format('ss'),
      });
    }, 1000);
    return () => clearInterval(timer); // Cleanup on component unmount
  });

  return (
    <div className="grid auto-cols-max grid-flow-col gap-5 text-center">
      <div className="flex flex-col rounded-box bg-slate-800 p-3 text-white">
        <span className="countdown font-mono text-5xl">{time.hours}</span>
        часы
      </div>
      <div className="flex flex-col rounded-box bg-slate-800 p-3 text-white">
        <span className="countdown font-mono text-5xl">{time.minutes}</span>
        минуты
      </div>
      <div className="flex flex-col rounded-box bg-slate-800 p-3 text-white">
        <span className="countdown font-mono text-5xl">{time.seconds}</span>
        секунды
      </div>
    </div>
  );
};
