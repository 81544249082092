import { forwardRef } from 'react';
import ReactSelect, { type Props } from 'react-select';
import makeAnimated from 'react-select/animated';

import { twMerge } from 'tailwind-merge';

const animatedComponents = makeAnimated();

// ----------------------------------------------------------------

interface SelectProps extends Omit<Props, 'components'> {
  label?: string;
  wrapperClassName?: string;
  labelClassName?: string;
}

// ----------------------------------------------------------------

export const Select = forwardRef<any, SelectProps>((props, ref) => {
  const {
    label,
    wrapperClassName,
    labelClassName,
    name,
    placeholder,
    closeMenuOnSelect = false,
    ...rest
  } = props;

  return (
    <div className={twMerge('from-control mb-3 min-w-52', wrapperClassName)}>
      {label && (
        <label
          htmlFor={name}
          className={twMerge('label font-medium text-gray-500', labelClassName)}
        >
          {label}
        </label>
      )}
      <ReactSelect
        ref={ref}
        placeholder={placeholder}
        menuPortalTarget={document.body}
        name={name}
        inputId={name}
        closeMenuOnSelect={closeMenuOnSelect}
        components={animatedComponents}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 60 }),
          multiValueLabel: base => ({
            ...base,
            // textOverflow: 'unset',
            // paddingLeft: 3,
          }),
          // container: base => ({ ...base, backgroundColor: 'red' }),
          valueContainer: (base, { children }) => {
            return { ...base };
          },
        }}
        {...rest}
      />
    </div>
  );
});

// ----------------------------------------------------------------

if (process.env.NODE_ENV !== 'production') {
  Select.displayName = 'Select';
}
