import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { Button } from '@/components/Button';

// =================================================================

interface PickMenuCardUIProps {
  title: string;
  price: number;
  handleIncreament: VoidFunction;
}

// =================================================================

export const PickMenuCardUI = (props: PickMenuCardUIProps) => {
  const { handleIncreament, price, title } = props;

  return (
    <Button
      variant="neutral"
      onClick={handleIncreament}
      className={'h-24 w-full justify-start gap-0 text-left text-lg'}
    >
      <p>{title}</p>
      <p className="w-full text-right text-base font-medium text-primary">
        {thousandSeperator(price)} сум
      </p>
    </Button>
  );
};
