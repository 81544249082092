import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { OrderApi } from '@/api/domains/order.api';
import { OrderStatusEnum } from '@/enums/order.enum';

import { OrderQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useOrderList = () => {
  const [searchParams] = useSearchParams();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageSize: 15,
    pageIndex: Number(searchParams.get('page')) || 1,
  });

  const search = searchParams.get('search');
  const status = searchParams.get('status');
  const checkPrinted = searchParams.get('checkPrinted');

  const pagination = useMemo(() => ({ pageSize, pageIndex }), [pageIndex, pageSize]);

  const {
    data: orders,
    isLoading,
    isPlaceholderData,
  } = useQuery({
    queryKey: OrderQueryKeys.orderList(
      pageIndex,
      pageSize,
      search || '',
      status || 'all',
      Boolean(checkPrinted),
    ),
    queryFn: () =>
      OrderApi.orderList({
        page: pageIndex,
        limit: pageSize,
        search,
        statuses: status ? (status.toLocaleUpperCase() as OrderStatusEnum) : undefined,
        check_printed: checkPrinted ? Boolean(checkPrinted) : undefined,
      }),
    placeholderData: keepPreviousData,
    staleTime: 0,
    refetchInterval: 5000,
  });

  return { orders, isLoading, isPlaceholderData, pagination, setPagination };
};
