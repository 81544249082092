import { useQuery, keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ExpensesApi } from '@/api/domains/expenses.api';

import { ExpensesQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useExpense = () => {
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageSize: 15,
    pageIndex: Number(searchParams.get('page')) || 1,
  });

  const pagination = useMemo(() => ({ pageSize, pageIndex }), [pageIndex, pageSize]);

  const {
    data: list,
    isLoading,
    isPlaceholderData,
  } = useQuery({
    queryKey: ExpensesQueryKeys.expensesList(pageIndex, pageSize),
    queryFn: () =>
      ExpensesApi.list({
        page: pageIndex,
        limit: pageSize,
      }),
    placeholderData: keepPreviousData,
    staleTime: 0,
  });

  const invalidateExpensesList = async () => {
    await queryClient.invalidateQueries({
      queryKey: ExpensesQueryKeys.expensesList(pagination.pageIndex, pagination.pageSize),
    });
  };

  return { list, isLoading, isPlaceholderData, pagination, setPagination, invalidateExpensesList };
};
