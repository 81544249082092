import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { ExpensesApi } from '@/api/domains/expenses.api';

import { ExpensesQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useWaitersList = () => {
  const { data } = useQuery({
    queryKey: ExpensesQueryKeys.waitersList(),
    queryFn: ExpensesApi.getWaitersList,
    staleTime: Infinity,
  });

  const waitersList = useMemo(() => {
    if (!data) return [];

    return data.results.map(result => ({ value: result.id, label: result.name }));
  }, [data]);

  return { waitersList };
};
