import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useMenuList } from '@/pages/menu/useMenuList';

import { Button } from '@/components/Button';

interface SelectableMenuListProps {
  menuId: number | null;
  handleSelect: (id: number) => void;
}

export const SelectableMenuList = (props: SelectableMenuListProps) => {
  const { handleSelect, menuId } = props;

  const { isLoading, menuList } = useMenuList();

  if (isLoading || !menuList) return <h3>Loading...</h3>;

  return (
    <ul>
      {menuList.results.map(menu => (
        <li key={menu.category_id}>
          <h4 className="mb-2 rounded-lg bg-gray-300 p-2 text-lg font-medium">
            {menu.category_name}
          </h4>
          <ul className="mb-4 grid grid-cols-2 gap-4">
            {menu.menus.map(item => (
              <li key={item.id}>
                <Button
                  variant={item.id === menuId ? 'primary' : 'secondary'}
                  title={item.name}
                  onClick={() => handleSelect(item.id)}
                  className="h-auto w-full flex-col gap-0 py-1.5 text-left text-base font-medium"
                >
                  <p>{item.name}</p>
                  <p className="font-normal">{thousandSeperator(item.price)} сум</p>
                </Button>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};
