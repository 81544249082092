import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { PaymentTypeEnum } from '@/enums/order.enum';

import { Button } from '@/components/Button';
import { NumberInput } from '@/components/Inputs/NumberInput';
import { usePaymentMethods } from '@/components/OrderInfoModal/PaymentProvider';

// =================================================================

type FormValues = {
  card: string;
};

// =================================================================

export const PaymentWithCard = () => {
  const { isLoading, onPay, initialValue } = usePaymentMethods();

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: { card: initialValue.toString() },
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const { card } = data;

    if (!card) return;

    const payments = [] as { price: number; payment_type: PaymentTypeEnum }[];
    payments.push({ price: Number(card), payment_type: PaymentTypeEnum.CARD });

    await onPay(payments);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="card"
        control={control}
        render={({ field }) => {
          const { onChange, onBlur, ...rest } = field;
          return (
            <NumberInput
              label="Полученная сумма (Банковская карта)"
              placeholder="Введите сумму"
              className="mb-3"
              thousandSeparator={' '}
              decimalSeparator="."
              autoFocus
              onValueChange={params => {
                onChange(params.value);
              }}
              {...rest}
            />
          );
        }}
      />
      <Button
        type="submit"
        variant="primary"
        disabled={isLoading}
        className="h-auto w-full py-4 text-xl"
      >
        Оплатить
      </Button>
    </form>
  );
};
