import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTableList } from '@/pages/tables/useTableList';

import { Select } from '@/components/Select';

// =================================================================

type SelectType = {
  label: string;
  value: string;
};

// =================================================================

export const SelectTableNumber = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { tableList } = useTableList();

  const tableListOptions = useMemo(() => {
    if (!tableList) {
      return undefined;
    }

    return tableList.results.map(table => ({
      value: table.id.toString(),
      label: table.number.toString(),
    }));
  }, [tableList]);

  const handleSelect = (event: SelectType | null) => {
    if (event === null) {
      searchParams.delete('tableId');
      setSearchParams(searchParams);
    } else {
      setSearchParams(searchParams => {
        searchParams.set('tableId', event.value);
        return searchParams;
      });
    }
  };

  return (
    <Select
      onChange={event => handleSelect(event as SelectType | null)}
      label="Выберите стол"
      placeholder="Выберите стол"
      closeMenuOnSelect
      wrapperClassName="mb-0"
      name="tableNumber"
      labelClassName="p-0 text-sm mb-0.5"
      options={tableListOptions}
      isClearable
    />
  );
};
