import { memo, Fragment } from 'react';

import { useMenuList } from '@/pages/menu/useMenuList';

import { PageTitle } from '@/components/PageTitle';

import { MenuCard } from '@/pages/menu/MenuCard';
import { MenuCategoryList } from '@/pages/menu/MenuCategoryList';

export const MenuList = memo(() => {
  const { menuList, isLoading } = useMenuList();

  if (!menuList && isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <Fragment>
      <MenuCategoryList className="mb-8" />
      {menuList?.results.map(list => (
        <div key={list.category_id} className="mb-4">
          <div className="mb-4 rounded-lg bg-gray-300 px-4 py-3">
            <PageTitle title={list.category_name} />
          </div>
          <div className="grid grid-cols-4 gap-6">
            {list.menus.map(menu => (
              <MenuCard key={menu.id} menu={menu} />
            ))}
          </div>
        </div>
      ))}
    </Fragment>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  MenuList.displayName = 'MenuList';
}
