import { Fragment, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { twMerge } from 'tailwind-merge';

import { OrderStatusEnum } from '@/enums/order.enum';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';
import { Tabs } from '@/components/Tabs';

import { OrderInfo } from '@/pages/orders/OrderInfo';
import { OrdersTable } from '@/pages/orders/OrdersTable';

export const OrdersList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [orderId, setOrderId] = useState<number | null>(null);

  useEffectOnce(() => {
    setSearchParams(searchParams => {
      searchParams.delete('checkPrinted');
      searchParams.set('page', '1');
      searchParams.set('status', 'new');
      return searchParams;
    });
  });

  const handleSelectOrder = (orderId: number) => {
    setOrderId(orderId);
  };

  const clearOrderId = useCallback(() => {
    setOrderId(null);
  }, []);

  const handleChangeStatus = (status: OrderStatusEnum | null) => {
    if (status) {
      setSearchParams({ status: status.toLowerCase(), page: '1' });
    } else {
      searchParams.delete('status');
      setSearchParams(searchParams);
    }
  };

  return (
    <Fragment>
      <PageTitle title="Заказы" className="mb-4" />
      <Tabs
        renderTab={({ child, isActive, onClick }) => (
          <Button onClick={onClick} variant={isActive ? 'primary' : 'neutral'}>
            {child}
          </Button>
        )}
        switcherClassName="gap-4 mb-5"
        tabPanelsClassName={twMerge('pr-0', orderId && 'pr-[460px]')}
      >
        <Tabs.Switcher>
          <Tabs.Tab
            onClick={() => {
              handleChangeStatus(OrderStatusEnum.NEW);
            }}
          >
            Открытый
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => {
              setSearchParams({ checkPrinted: 'true', page: '1' });
            }}
          >
            Закрытый
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => {
              handleChangeStatus(OrderStatusEnum.PAID);
            }}
          >
            Оплачено
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => {
              handleChangeStatus(null);
            }}
          >
            Все
          </Tabs.Tab>
        </Tabs.Switcher>
        <Tabs.Panels>
          <Tabs.Panel>
            <OrdersTable
              key={searchParams.get('status') || 'all'}
              handleSelectOrder={handleSelectOrder}
            />
            <OrderInfo orderId={orderId} clearOrderId={clearOrderId} />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </Fragment>
  );
};
