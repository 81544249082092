import { useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import dayjs, { type Dayjs } from 'dayjs';

import { ExpensesApi } from '@/api/domains/expenses.api';
import { ErrorResponse } from '@/api/types/common.types';
import { TransactionEnum } from '@/enums/expenses.enum';

import { useExpense } from '@/pages/expenses/useExpense';
import { useExpensesCategories } from '@/pages/expenses/useExpensesCategories';

import { useNotification } from '@/contexts/NotificationContext';

import { Button } from '@/components/Button';
import { DatePicker } from '@/components/Inputs/DatePicker';
import { NumberInput } from '@/components/Inputs/NumberInput';
import { TextArea } from '@/components/Inputs/TextArea';
import { Modal, type ModalProps } from '@/components/Modal';
import { Select } from '@/components/Select';

// =================================================================

type ExpensesForm = {
  date: Dayjs | null;
  price: string;
  description: string;
  category: { label: string; value: number } | null;
};

// =================================================================

const iniatValue: ExpensesForm = {
  date: dayjs(),
  price: '',
  description: '',
  category: null,
};

// =================================================================

export const IncomeModal = (
  props: Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'>,
) => {
  const { isOpen, onClose, ...rest } = props;

  const { notification } = useNotification();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const { categoryList } = useExpensesCategories();
  const { invalidateExpensesList } = useExpense();

  const { handleSubmit, control, reset } = useForm<ExpensesForm>({
    defaultValues: iniatValue,
  });

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      reset(iniatValue);
      setIsLoading(false);
      onClose();
    };
  }

  const onSubmit: SubmitHandler<ExpensesForm> = async data => {
    const { category, date, price, description } = data;
    if (!category || !date) return;

    setIsLoading(true);
    try {
      await ExpensesApi.createExpense({
        price: Number(price),
        category_id: category.value,
        description,
        tr_type: TransactionEnum.IN,
        created_at: date.format('HH:mm / DD.MM.YYYY'),
      });
      await invalidateExpensesList();
      onCloseRef.current?.();
      notification({ type: 'success', message: 'Успешно добавлено!' });
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { error: errorMsg } = error.response as ErrorResponse;
      notification({ type: 'error', message: errorMsg });
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseRef.current}
      canOutsideClickClose={false}
      className="max-w-[686px]"
      {...rest}
    >
      <h3 className="text-center text-2xl font-semibold">
        {searchParams.get('incomeId') ? 'Изменить' : 'Новый'} Приход
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} className="pt-8">
        <Controller
          name="date"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            const { value, onChange } = field;

            return (
              <DatePicker
                {...field}
                value={value}
                format={'DD.MM.YYYY / HH:mm'}
                showTime
                className="w-full"
                onChange={date => onChange(date)}
                label="Выберите дату"
              />
            );
          }}
        />
        <Controller
          name="category"
          control={control}
          render={({ field }) => {
            const { value, ...rest } = field;
            return (
              <Select
                {...rest}
                required
                value={value}
                label="Категория"
                placeholder="Выберите категория"
                closeMenuOnSelect
                isClearable
                options={categoryList}
              />
            );
          }}
        />
        <Controller
          name="price"
          control={control}
          render={({ field }) => {
            const { onChange, onBlur, ...rest } = field;

            return (
              <NumberInput
                label="Сумма"
                placeholder="Введите сумму"
                className="mb-1"
                thousandSeparator={' '}
                decimalSeparator="."
                autoFocus
                onValueChange={params => {
                  onChange(params.value);
                }}
                {...rest}
              />
            );
          }}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => {
            return <TextArea label="Описание" placeholder="Введите описание" {...field} />;
          }}
        />
        <Button variant="primary" disabled={isLoading} type="submit" className="mt-5 w-full">
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
