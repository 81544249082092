import {
  ColumnDef,
  TableOptions,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment, useEffect, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { Button } from '@/components/Button';

import { ReactComponent as ArrowLeftSVG } from '@/assets/icons/arrow-left.svg';

// =================================================================

type TableProps<TData> = Omit<
  TableOptions<TData>,
  'data' | 'columns' | 'getCoreRowModel' | 'debugTable' | 'debugHeaders' | 'debugColumns'
> & {
  data: TData[];
  columns: ColumnDef<TData>[];
  isLoading?: boolean;
  title?: string;
  searchBar?: ReactNode;
  onClickRow?: (id: number) => void;
};

const isTest = process.env.NODE_ENV !== 'production';

// =================================================================

export function Table<TData>(props: TableProps<TData>) {
  const {
    data = [],
    columns,
    title,
    isLoading = false,
    manualPagination = true,
    searchBar,
    onClickRow,
    ...restOfProps
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: isTest,
    debugHeaders: isTest,
    debugColumns: isTest,
    manualPagination,
    ...restOfProps,
  });

  const page = table.getState().pagination.pageIndex;

  useEffect(() => {
    setSearchParams(searchParams => {
      searchParams.set('page', page.toString());
      return searchParams;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="select-none rounded-md border border-gray">
      {/* pagination section */}
      <div className="flex items-center justify-between border-b p-4">
        {searchBar}
        <div className="ml-auto flex items-center justify-end gap-2">
          {isLoading && <span>Loading...</span>}
          <div className="join border border-gray">
            <Button
              variant="neutral"
              onClick={table.previousPage}
              disabled={table.getState().pagination.pageIndex < 2 || isLoading}
              className="border-1 join-item"
            >
              <ArrowLeftSVG />
            </Button>
            <Button className="join-item pointer-events-none border-b-0 border-t-0 border-gray">
              Page {page} of {table.getPageCount() - 1}
            </Button>
            <Button
              variant="neutral"
              onClick={table.nextPage}
              disabled={!table.getCanNextPage() || isLoading}
              className="join-item"
            >
              <ArrowLeftSVG className="rotate-180" />
            </Button>
          </div>
        </div>
      </div>
      {data.length !== 0 && (
        <div className="w-full overflow-x-auto">
          <table className="table table-md w-full whitespace-nowrap">
            <thead className="bg-gray">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="border-gray">
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="py-4 text-lg font-medium"
                      >
                        {header.isPlaceholder ? null : (
                          <Fragment>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </Fragment>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <tr
                    key={row.id}
                    className={twMerge(
                      onClickRow && 'cursor-pointer hover:bg-gray-100 hover:text-primary',
                      'border-gray',
                    )}
                    onClick={() => {
                      onClickRow?.((row.original as any).id);
                    }}
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id} className="py-4">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
