import { Fragment, useMemo, useState } from 'react';

import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { twMerge } from 'tailwind-merge';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useOrderStatistics } from '@/pages/cash/OrderStatistics/useOrderStatistics';

import { PageTitle } from '@/components/PageTitle';

const { RangePicker } = DatePicker;

export const OrderStatistics = () => {
  const [range, setRange] = useState<[Dayjs, Dayjs]>([
    dayjs().startOf('day'),
    dayjs().endOf('day'),
  ]);

  const orderStatistics = useOrderStatistics({
    startDate: range[0].format('DD-MM-YYYY'),
    endDate: range[1].format('DD-MM-YYYY'),
  });

  const orderTableList = useMemo(() => {
    if (!orderStatistics) return [];

    return [
      {
        order: 1,
        label: 'Открытые',
        count: orderStatistics.new_count,
        price: thousandSeperator(orderStatistics.new_price),
        service: thousandSeperator(orderStatistics.new_total_price - orderStatistics.new_price),
        totalPrice: thousandSeperator(orderStatistics.new_total_price),
      },
      {
        order: 2,
        label: 'Закрытые',
        count: orderStatistics.paid_count,
        price: thousandSeperator(orderStatistics.paid_price),
        service: thousandSeperator(orderStatistics.paid_total_price - orderStatistics.paid_price),
        totalPrice: thousandSeperator(orderStatistics.paid_total_price),
      },
      {
        order: 3,
        label: 'Сoбой',
        count: orderStatistics.pick_up_count,
        price: thousandSeperator(orderStatistics.pick_up_price),
        service: 0,
        totalPrice: thousandSeperator(orderStatistics.pick_up_price),
      },
      {
        order: 4,
        label: 'Итого',
        count:
          orderStatistics.new_count + orderStatistics.paid_count + orderStatistics.pick_up_count,
        price: thousandSeperator(
          orderStatistics.pick_up_price + orderStatistics.new_price + orderStatistics.paid_price,
        ),
        service: thousandSeperator(
          orderStatistics.new_total_price +
            orderStatistics.paid_total_price -
            orderStatistics.new_price -
            orderStatistics.paid_price,
        ),
        totalPrice: thousandSeperator(
          orderStatistics.new_total_price +
            orderStatistics.paid_total_price +
            orderStatistics.pick_up_price,
        ),
      },
    ];
  }, [orderStatistics]);

  const handleRangeChange: RangePickerProps['onChange'] = dates => {
    if (dates && dates[0] && dates[1]) {
      setRange([dates[0], dates[1]]);
    }
  };

  return (
    <Fragment>
      <div className="flex items-center justify-between gap-8">
        <PageTitle title="Статистика заказов" className="mb-4" />
        <RangePicker
          value={range}
          onChange={handleRangeChange}
          format="DD.MM.YYYY"
          placeholder={['Start Date', 'End Date']}
          size="large"
        />
      </div>
      <div className="mb-8 overflow-hidden rounded-lg border bg-white">
        <table className="table table-md w-full whitespace-nowrap">
          <thead className="bg-gray">
            <tr className="text-lg">
              <th>№</th>
              <th>Тип</th>
              <th>Количество</th>
              <th>Сумма блюда</th>
              <th>Обслуживание</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {orderTableList.map((item, index) => (
              <tr
                key={item.order}
                className={twMerge(
                  index !== orderTableList.length - 1 ? 'border-none' : 'font-bold',
                  index % 2 === 0 && 'bg-gray-100',
                )}
              >
                <td className="text-base">{item.order}</td>
                <td className="text-base font-bold">{item.label}</td>
                <td className="text-base">{item.count} шт</td>
                <td className="text-base">{item.price} сум</td>
                <td className="text-base">{item.service} сум</td>
                <td className="text-base">{item.totalPrice} сум</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
