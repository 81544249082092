import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { ExpensesApi } from '@/api/domains/expenses.api';

import { ExpensesQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useExpensesCategories = () => {
  const { data } = useQuery({
    queryKey: ExpensesQueryKeys.categoryList(),
    queryFn: ExpensesApi.categoryList,
    staleTime: Infinity,
  });

  const categoryList = useMemo(() => {
    if (!data) return [];

    return data.results.map(item => ({ label: item.name, value: item.id }));
  }, [data]);

  return { categoryList };
};
