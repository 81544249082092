import { useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MenuApi } from '@/api/domains/menu.api';
import { ErrorResponse } from '@/api/types/common.types';

import { MenuQueryKeys } from '@/contexts/QueryContext/query.keys';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';
import { type ModalProps, Modal } from '@/components/Modal';

import { MenuCategories } from '@/pages/menu/favorite-list/MenuCategories';
import { SelectableMenuList } from '@/pages/menu/favorite-list/SelectableMenuList';
import { PickUpSearchBar } from '@/pages/pick-up/PickUpSearchBar';

interface MenuSelectModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {
  orderCount: number | null;
}

export const MenuSelectModal = (props: MenuSelectModalProps) => {
  const { onClose, isOpen, orderCount, ...rest } = props;

  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectMenuId, setSelectedMenuId] = useState<number | null>(null);
  const [{ errorMsg, isLoading }, setSubmitStatus] = useState({
    errorMsg: '',
    isLoading: false,
  });

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      if (isLoading) return;
      searchParams.delete('categoryId');
      setSearchParams(searchParams);

      onClose();
      setSelectedMenuId(null);
    };
  }

  const handleSelectMenu = (id: number) => {
    setSelectedMenuId(id);
  };

  const handleSubmit = async () => {
    if (!selectMenuId || !orderCount) return;

    setSubmitStatus(prev => ({ ...prev, isLoading: true }));

    try {
      await MenuApi.addFavoriteList({ menu_id: selectMenuId, index: orderCount });
      await queryClient.invalidateQueries({
        queryKey: MenuQueryKeys.favoriteList(),
      });
      onCloseRef.current?.();
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { statusText } = error.response as ErrorResponse;
      setSubmitStatus(prev => ({ ...prev, errorMsg: statusText }));
    } finally {
      setSubmitStatus(prev => ({ ...prev, isLoading: false }));
    }
  };

  if (!isOpen && orderCount !== null) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseRef.current}
      canOutsideClickClose={false}
      className="min-h-[60vh] max-w-[60vw] p-0"
      {...rest}
    >
      <div className="p-8">
        <h3 className="text-2xl font-semibold">Выберите избранной меню</h3>
        {errorMsg && <Alert message={errorMsg} type="error" />}
        <MenuCategories />
        <PickUpSearchBar />
        <SelectableMenuList menuId={selectMenuId} handleSelect={handleSelectMenu} />
      </div>
      {selectMenuId && (
        <div className="sticky bottom-0 left-0 z-[5] w-full bg-white px-8 pb-8 pt-4">
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isLoading}
            className="w-full text-xl"
          >
            Submit
          </Button>
        </div>
      )}
    </Modal>
  );
};
