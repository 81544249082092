import { useMenuList } from '@/pages/menu/useMenuList';
import type { MenuList as MenuListType } from '@/pages/order-details/useOrderDetails';

import { PickMenuCardUI } from '@/pages/pick-up/PickUpCardUI';

interface MenuListProps {
  handleIncreament: (menu: MenuListType) => void;
}

export const MenuList = (props: MenuListProps) => {
  const { handleIncreament } = props;

  const { menuList, isLoading } = useMenuList();

  if (!menuList || isLoading) {
    return <h3>Loading...</h3>;
  }

  return (
    <div className="mt-8">
      {menuList.results.map(category => (
        <div key={category.category_id} className="mb-6">
          <h3 className="mb-6 text-2xl font-semibold">{category.category_name}</h3>
          {category.menus.length > 0 && (
            <ul className="grid grid-cols-2 gap-4 xl:grid-cols-3">
              {category.menus.map(menu => (
                <li key={menu.id}>
                  <PickMenuCardUI
                    title={menu.name}
                    price={menu.price}
                    handleIncreament={() =>
                      handleIncreament({
                        count: 1,
                        id: menu.id,
                        name: menu.name,
                        price: menu.price,
                      })
                    }
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};
