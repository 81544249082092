import type { MenuList } from '@/api/types/menu.types';

import { PickMenuCardUI } from '@/pages/pick-up/PickUpCardUI';
import { usePickUpMethods } from '@/pages/pick-up/PickUpProvider';

// =================================================================

interface PickUpMenuCardProps {
  menu: MenuList;
}

// =================================================================

export const PickUpMenuCard = (props: PickUpMenuCardProps) => {
  const { menu } = props;

  const { increaseMenuItem } = usePickUpMethods();

  const handleIncreament = () => {
    increaseMenuItem({
      id: menu.id,
      title: menu.name,
      count: 1,
      poster: menu.photos[0],
      price: menu.price,
    });
  };

  return (
    <PickMenuCardUI title={menu.name} handleIncreament={handleIncreament} price={menu.price} />
  );
};
