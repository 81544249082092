import { createContext, useContext } from 'react';
import { ToastContainer, type ToastOptions, toast, Bounce } from 'react-toastify';

import type { IPropsChildren } from '@/types/common.types';

import 'react-toastify/dist/ReactToastify.css';

// =================================================================

type NotificationType = 'error' | 'success';

type NotificationContextState = {
  notification: (params: {
    type: NotificationType;
    message?: string;
    options?: ToastOptions<unknown>;
  }) => void;
};

// =================================================================

const NotificationContext = createContext<NotificationContextState | null>(null);

const notification = (params: {
  type: NotificationType;
  message?: string;
  options?: ToastOptions<unknown>;
}) => {
  const { type, message, options } = params;

  toast[type](message, {
    transition: Bounce,
    hideProgressBar: false,
    ...options,
  });
};

// =================================================================

export const NotificationProvider = (props: IPropsChildren) => {
  const { children } = props;

  return (
    <NotificationContext.Provider value={{ notification }}>
      {children}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        hideProgressBar
        theme="colored"
      />
    </NotificationContext.Provider>
  );
};

// =================================================================

export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification should be used within NotificationProvider');
  }

  return context;
};
