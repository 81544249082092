import { useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OrderApi } from '@/api/domains/order.api';
import type { OrderDetails } from '@/api/types/order.types';

import { useNotification } from '@/contexts/NotificationContext';
import { OrderQueryKeys } from '@/contexts/QueryContext/query.keys';

import { Button } from '@/components/Button';
import { Modal, type ModalProps } from '@/components/Modal';
import { OrderDetailsCard } from '@/components/OrderDetailsCard';

import { ReactComponent as MinusSVG } from '@/assets/icons/minus.svg';
import { ReactComponent as PlusSVG } from '@/assets/icons/plus.svg';

// =================================================================

interface OrderEditModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {
  orderDetails: OrderDetails;
}

// =================================================================

export const OrderEditModal = (props: OrderEditModalProps) => {
  const { isOpen, onClose, orderDetails, ...rest } = props;

  const { notification } = useNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [menuList, setMenuList] = useState<Array<{ order_menu_id: number; count: number }>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      onClose();
      setMenuList([]);
    };
  }

  // ----------------------------------------------------------------

  const increaseMenuItem = (menu: { order_menu_id: number; count: number }) => {
    setMenuList(prev => {
      const existItemIndex = prev.findIndex(item => item.order_menu_id === menu.order_menu_id);
      if (existItemIndex !== -1) {
        prev.splice(existItemIndex, 1, {
          ...prev[existItemIndex],
          count: prev[existItemIndex].count + 1,
        });
        return [...prev];
      }

      return [...prev, { ...menu, count: 1 }];
    });
  };

  // ----------------------------------------------------------------

  const decreaseMenuItem = (itemId: number) => {
    setMenuList(prev => {
      const existItemIndex = prev.findIndex(item => item.order_menu_id === itemId);
      if (existItemIndex !== -1) {
        prev.splice(existItemIndex, 1, {
          ...prev[existItemIndex],
          count: prev[existItemIndex].count - 1,
        });
      }

      return [...prev];
    });
  };

  // ----------------------------------------------------------------

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await OrderApi.cancelOrder({
        id: orderDetails.id,
        menus: menuList,
      });
      await queryClient.invalidateQueries({
        queryKey: OrderQueryKeys.orderDetails(orderDetails.id),
      });
      onCloseRef.current?.();
      navigate('/orders?page=1&status=new');
      notification({ type: 'success', message: 'Еда успешно отменена!' });
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      notification({ type: 'error', message: error.response.statusText });
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------------------------------------------

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseRef.current}
      canOutsideClickClose={true}
      className="max-w-[736px]"
      {...rest}
    >
      <OrderDetailsCard.Title className="mb-4">Отказ блюда</OrderDetailsCard.Title>
      {orderDetails.menus.map(menu => (
        <OrderMenuCard
          key={menu.id}
          title={`${menu.count}x ${menu.name}`}
          handleDecrement={() => decreaseMenuItem(menu.id)}
          handleIncrement={() =>
            increaseMenuItem({ order_menu_id: menu.order_menu_id, count: menu.count })
          }
          maxCount={menu.count}
        />
      ))}
      <Button
        variant={menuList.length === 0 || isLoading ? 'outline' : 'primary'}
        disabled={menuList.length === 0 || isLoading}
        onClick={handleSubmit}
        className="mt-6 h-auto w-full py-3 text-2xl"
      >
        Подтвердить
      </Button>
    </Modal>
  );
};

// =================================================================

interface OrderMenuCardProps {
  title: string;
  handleIncrement: VoidFunction;
  handleDecrement: VoidFunction;
  maxCount: number;
}

const OrderMenuCard = (props: OrderMenuCardProps) => {
  const { title, handleDecrement, handleIncrement } = props;

  const [count, setCount] = useState(0);

  const increament = () => {
    setCount(prev => prev + 1);
    handleIncrement();
  };

  const decreament = () => {
    setCount(prev => prev - 1);
    handleDecrement();
  };

  return (
    <OrderDetailsCard.Price
      leftText={title}
      rightText={
        <div className="ml-auto flex items-center gap-4">
          <Button variant="neutral" disabled={count === 0} onClick={decreament}>
            <MinusSVG width={18} height={18} />
          </Button>
          <p className="w-7 text-center text-lg font-medium">{count}</p>
          <Button variant="neutral" disabled={count >= props.maxCount} onClick={increament}>
            <PlusSVG width={18} height={18} />
          </Button>
        </div>
      }
      className="mb-3 select-none"
    />
  );
};
