import { useQuery } from '@tanstack/react-query';
import { useState, useMemo } from 'react';

import { OrderApi } from '@/api/domains/order.api';

import { OrderQueryKeys } from '@/contexts/QueryContext/query.keys';

// =================================================================

export type MenuList = {
  id: number;
  name: string;
  count: number;
  price: number;
};

// =================================================================

export const useOrderDetails = (config: { orderId: number | null }) => {
  const { orderId } = config;

  const [menuList, setMenuList] = useState<MenuList[]>([]);

  const totalPrice = useMemo(() => {
    return menuList.reduce((prev, acc) => prev + acc.price * acc.count, 0);
  }, [menuList]);

  const { data, isLoading } = useQuery({
    queryKey: OrderQueryKeys.orderDetails(orderId || ''),
    queryFn: () => OrderApi.orderDetails(orderId || ''),
    staleTime: Infinity,
    enabled: Boolean(orderId),
  });

  // ----------------------------------------------------------------

  const increaseMenuItem = (menu: MenuList) => {
    setMenuList(prev => {
      const existItemIndex = prev.findIndex(item => item.id === menu.id);
      if (existItemIndex !== -1) {
        prev.splice(existItemIndex, 1, {
          ...prev[existItemIndex],
          count: prev[existItemIndex].count + 1,
        });
        return [...prev];
      }

      return [...prev, { ...menu, count: 1 }];
    });
  };

  // ----------------------------------------------------------------

  const decreaseMenuItem = (itemId: number) => {
    setMenuList(prev => {
      const existItemIndex = prev.findIndex(item => item.id === itemId);
      if (existItemIndex !== -1) {
        prev.splice(existItemIndex, 1, {
          ...prev[existItemIndex],
          count: prev[existItemIndex].count - 1,
        });
      }
      if (prev[existItemIndex].count === 0) {
        prev.splice(existItemIndex, 1);
      }

      return [...prev];
    });
  };

  return { order: data, isLoading, menuList, increaseMenuItem, decreaseMenuItem, totalPrice };
};
