import { Fragment, useState, useCallback } from 'react';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';

import { ExpenseModal } from '@/pages/expenses/ExpenseModal';
import { ExpensesTable } from '@/pages/expenses/ExpensesTable';
import { IncomeModal } from '@/pages/expenses/IncomeModal';
import { SalaryModal } from '@/pages/expenses/SalaryModal';

export const ExpensesPage = () => {
  const [isOpenExpenseModal, setIsOpenExpenseModal] = useState(false);
  const [isOpenIncomeModal, setIsOpenIncomeModal] = useState(false);
  const [isOpenSalaryModal, setIsOpenSalaryModal] = useState(false);

  const handleOpenExpenseModal = useCallback(() => {
    setIsOpenExpenseModal(true);
  }, []);

  const handleOpenIncomeModal = useCallback(() => {
    setIsOpenIncomeModal(true);
  }, []);

  const handleOpenSalaryModal = useCallback(() => {
    setIsOpenSalaryModal(true);
  }, []);

  const handleCloseIncomeModal = useCallback(() => {
    setIsOpenIncomeModal(false);
  }, []);

  const handleCloseExpenseModal = useCallback(() => {
    setIsOpenExpenseModal(false);
  }, []);

  const handleCloseSalaryModal = useCallback(() => {
    setIsOpenSalaryModal(false);
  }, []);

  return (
    <Fragment>
      <div className="mb-6 flex items-center justify-between">
        <PageTitle title="Расходы с кассы" />
        <div className="flex items-center gap-4">
          <Button variant="accent" className="text-white" onClick={handleOpenSalaryModal}>
            Зарплата
          </Button>
          <Button variant="success" className="text-white" onClick={handleOpenIncomeModal}>
            Приход
          </Button>
          <Button variant="primary" onClick={handleOpenExpenseModal}>
            Расход
          </Button>
        </div>
      </div>
      <ExpensesTable />
      <ExpenseModal key="expense" isOpen={isOpenExpenseModal} onClose={handleCloseExpenseModal} />
      <IncomeModal key="income" isOpen={isOpenIncomeModal} onClose={handleCloseIncomeModal} />
      <SalaryModal key="salary" isOpen={isOpenSalaryModal} onClose={handleCloseSalaryModal} />
    </Fragment>
  );
};
