import { useRef } from 'react';

import { OrderStatusEnum } from '@/enums/order.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useOrderDetails } from '@/pages/order-details/useOrderDetails';

import { Badge, type BadgeType } from '@/components/Badge';
import { type ModalProps, Modal } from '@/components/Modal';
import { OrderDetailsCard } from '@/components/OrderDetailsCard';
import { PaymentForm } from '@/components/OrderInfoModal/PaymentForm';

// =================================================================

interface OrderInfoModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {
  orderId: string;
}

// =================================================================

const orderStatusType: Record<OrderStatusEnum, BadgeType> = {
  NEW: 'accent',
  PAID: 'success',
  CANCELLED: 'error',
  SERVED: 'info',
};

// =================================================================

export const OrderInfoModal = (props: OrderInfoModalProps) => {
  const { onClose, isOpen, orderId, ...rest } = props;

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      onClose();
    };
  }

  const { order } = useOrderDetails({ orderId: Number(orderId) });

  if (!order || !isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseRef.current}
      canOutsideClickClose={false}
      className="max-w-[736px]"
      {...rest}
    >
      <OrderDetailsCard.Title className="mb-4">Информация о столе</OrderDetailsCard.Title>
      <OrderDetailsCard.Price
        leftText="Номер стола"
        rightText={order.table_number ? `№${order.table_number}` : 'сoбой'}
        className="mb-4"
      />
      <OrderDetailsCard.Price
        leftText="Количество гостей"
        rightText={`${order.client_count} человека`}
        className="mb-4"
      />
      <OrderDetailsCard.Price
        leftText="ФИО официанта"
        rightText={order.waiter.name}
        className="mb-6"
      />
      <div className="mb-4 flex items-center justify-between">
        <OrderDetailsCard.Title>Информация о заказе</OrderDetailsCard.Title>
        <Badge
          text={order.status}
          type={orderStatusType[order.status]}
          className="px-5 py-4 text-base text-white"
        />
      </div>
      {order.menus.length > 0 && (
        <table className="mb-6 w-full">
          <thead>
            <tr>
              <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">№</td>
              <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">
                Название
              </td>
              <td className="border border-black-100 px-1.5 py-1 text-center text-sm font-semibold">
                К-во
              </td>
              <td className="border border-black-100 px-1.5 py-1 text-right text-sm font-semibold">
                Цена
              </td>
            </tr>
          </thead>
          <tbody>
            {order.menus.map((menu, index) => (
              <tr key={menu.id} className="text-sm">
                <td className="border border-black-100 px-1.5 py-1">{index + 1}</td>
                <td className="max-w-36 border border-black-100 px-1.5 py-1">{menu.name}</td>
                <td className="border border-black-100 px-1.5 py-1 text-center">{menu.count}</td>
                <td className="border border-black-100 px-1.5 py-1 text-right">
                  {thousandSeperator(menu.price)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <OrderDetailsCard.Price
        leftText={`Обслуживание ${order.service_percentage}%`}
        rightText={`${thousandSeperator(order.service_price)} сум`}
        className="mb-4"
      />
      <div className="mb-4 flex items-center justify-between">
        <OrderDetailsCard.Title>Итого</OrderDetailsCard.Title>
        <OrderDetailsCard.Title>{thousandSeperator(order.price)} сум</OrderDetailsCard.Title>
      </div>

      <PaymentForm orderId={orderId} onClose={onCloseRef.current} totalPrice={order.price} />
    </Modal>
  );
};
