import { useSearchParams } from 'react-router-dom';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';
import { Tabs } from '@/components/Tabs';

import { FavoriteMenuList } from '@/pages/pick-up/FavoriteMenuList';
import { MenuList } from '@/pages/pick-up/MenuList';
import { PickUpConfirmButton } from '@/pages/pick-up/PickUpConfirmButton';
import { PickUpProvider } from '@/pages/pick-up/PickUpProvider';
import { PickUpSearchBar } from '@/pages/pick-up/PickUpSearchBar';
import { SelectTableNumber } from '@/pages/pick-up/SelectTableNumber';

// =================================================================

const title: Record<string, string> = {
  pickUp: 'Сoбой',
  newOrder: 'Новый заказ',
};

// =================================================================

export const PickUpPage = () => {
  const [searchParams] = useSearchParams();

  return (
    <PickUpProvider>
      <PageTitle title={title[searchParams.get('title') || 'pickUp']} className="mb-6" />
      <Tabs
        renderTab={({ child, isActive, onClick }) => (
          <Button variant={isActive ? 'primary' : 'neutral'} onClick={onClick}>
            {child}
          </Button>
        )}
        switcherClassName="gap-4"
      >
        <div className="mb-5 flex items-end gap-4">
          <Tabs.Switcher>
            <Tabs.Tab>Все</Tabs.Tab>
            <Tabs.Tab>Избранное</Tabs.Tab>
          </Tabs.Switcher>
          {searchParams.get('title') === 'newOrder' && <SelectTableNumber />}
        </div>
        <Tabs.Panels>
          <Tabs.Panel>
            <PickUpSearchBar />
            <MenuList />
          </Tabs.Panel>
          <Tabs.Panel>
            <FavoriteMenuList />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
      <PickUpConfirmButton />
    </PickUpProvider>
  );
};
