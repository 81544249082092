import type { PaginationParams, ResponseWithPagination } from '@/api/types/common.types';
import type { ExpenseItem, ExpensesCategory, WaitersList } from '@/api/types/expenses.types';
import { TransactionEnum } from '@/enums/expenses.enum';
import { httpClient } from '@/services/http-client';

export const ExpensesApi = {
  list: async (params: PaginationParams) => {
    const { data } = await httpClient.get<ResponseWithPagination<ExpenseItem>>(
      '/cashier/branch-balance-history/list',
      { params },
    );

    return data;
  },

  // ----------------------------------------------------------------

  categoryList: async () => {
    const { data } = await httpClient.get<ResponseWithPagination<ExpensesCategory>>(
      '/cashier/branch-balance-history-category/list',
    );

    return data;
  },

  // ----------------------------------------------------------------

  createExpense: async (body: {
    price: number;
    tr_type: TransactionEnum;
    category_id?: number;
    created_at: string; // HH:mm / DD.MM.YYYY
    waiter_id?: number;
    description?: string;
  }) => {
    return await httpClient.post('/cashier/branch-balance-history/create', body);
  },

  // ----------------------------------------------------------------

  expensesDetails: async (id: string) => {
    const { data } = await httpClient.get<ExpenseItem>(`/cashier/branch-balance-history/${id}`);

    return data;
  },

  // ----------------------------------------------------------------

  updateExpense: async (params: {
    body: {
      price: number;
      tr_type: TransactionEnum;
      category_id?: number;
      created_at: string;
      waiter_id?: number;
      description?: string;
    };
    id: number;
  }) => {
    const { id, body } = params;

    return await httpClient.patch(`/cashier/branch-balance-history/${id}`, body);
  },

  // ----------------------------------------------------------------

  deleteExpense: async (id: string) => {
    return await httpClient.delete(`/cashier/branch-balance-history/${id}`);
  },

  // ----------------------------------------------------------------

  getWaitersList: async () => {
    const { data } =
      await httpClient.get<ResponseWithPagination<WaitersList>>('/cashier/waiter/list');

    return data;
  },
};
