import { useQuery } from '@tanstack/react-query';

import { StatisticsApi } from '@/api/domains/statistics.api';

import { StatisticsQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useOrderStatistics = (config: { endDate: string; startDate: string }) => {
  const { endDate, startDate } = config;

  const { data } = useQuery({
    queryKey: StatisticsQueryKeys.order(startDate, endDate),
    queryFn: () => StatisticsApi.orderStatistics({ from: startDate, to: endDate }),
    staleTime: 0,
    enabled: Boolean(startDate && endDate),
    refetchInterval: 3000,
  });

  return data;
};
