import React from 'react';

import { DatePicker as AntdDatePicker, type DatePickerProps as AntdDatePickerProps } from 'antd';
import { twMerge } from 'tailwind-merge';

interface DatePickerProps extends AntdDatePickerProps {
  wrapperClassName?: string;
  label?: React.ReactNode;
  labelClassName?: string;
}

export const DatePicker = React.forwardRef<HTMLElement, DatePickerProps>((props, ref) => {
  const {
    minDate,
    maxDate,
    format = 'DD.MM.YYYY',
    placeholder = 'Выберите дату',
    showTime = false,
    name,
    className,
    wrapperClassName,
    labelClassName,
    label,
    ...rest
  } = props;

  return (
    <div className={twMerge('from-control mb-3', wrapperClassName)}>
      {label && (
        <label
          htmlFor={name}
          className={twMerge('label flex justify-start font-medium text-gray-500', labelClassName)}
        >
          {label}
        </label>
      )}

      <AntdDatePicker
        ref={ref as any}
        format={format}
        name={name}
        placeholder={placeholder}
        showTime={showTime}
        showSecond={false}
        disabledDate={current =>
          Boolean(
            (minDate && current && current.isBefore(minDate, 'day')) ||
              (maxDate && current && current.isAfter(maxDate, 'day')),
          )
        }
        // cellRender={(props, info) => {
        //   const { today, originNode, type, prefixCls, locale } = info;
        //   console.log({ today: today.toString() });
        //   return React.cloneElement(originNode, {
        //     className: twMerge(originNode.props.className, 'text-base p-2'),
        //   });
        // }}
        // defaultOpen
        // components={{
        //   input: props => <input {...props} />,
        //   date: ({ value }) => <span>{value?.date()}</span>,
        // }}
        {...rest}
        size="large"
        className={className}
      />
    </div>
  );
});
