import { Suspense } from 'react';

import { Sidebar } from '@/layout/Sidebar';

import { IPropsChildren } from '@/types/common.types';

export const Layout = (props: IPropsChildren) => {
  const { children } = props;

  return (
    <main className="pl-20">
      <Sidebar />
      <section className="overflow-auto pt-3">
        <div className="px-6 pb-8 pt-4">
          <Suspense fallback={<h3>Loading...</h3>}>{children}</Suspense>
        </div>
      </section>
    </main>
  );
};
