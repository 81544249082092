import { forwardRef, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

// ----------------------------------------------------------------

interface TextAreaProps extends React.ComponentProps<'textarea'> {
  label?: ReactNode;
  labelClassName?: string;
  wrapperClassName?: string;
}

// ----------------------------------------------------------------

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { label, name, labelClassName, wrapperClassName, className, ...inputProps } = props;

  return (
    <div className={twMerge('from-control mb-3', wrapperClassName)}>
      {label && (
        <label
          htmlFor={name}
          className={twMerge('label flex justify-start font-medium text-gray-500', labelClassName)}
        >
          {label}
        </label>
      )}
      <textarea
        ref={ref}
        id={name}
        name={name}
        rows={5}
        className={twMerge(
          'textarea input-bordered w-full border-gray-200 bg-gray-300 text-base focus-within:outline-none focus:border-gray-400 focus:outline-none focus:ring-0',
          className,
        )}
        {...inputProps}
      />
    </div>
  );
});

// ----------------------------------------------------------------

if (process.env.NODE_ENV !== 'production') {
  TextArea.displayName = 'TextArea';
}
