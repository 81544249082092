import { Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useStore } from '@/contexts/StoreContext';

import { Button } from '@/components/Button';
import { Tabs } from '@/components/Tabs';

import { OrdersList } from '@/pages/orders/OrdersList';
import { PickUpPage } from '@/pages/pick-up';

import { ReactComponent as PlusSVG } from '@/assets/icons/plus.svg';

export const OrdersPage = () => {
  const store = useStore(store => store.pickUpStore);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  return (
    <Fragment>
      <Tabs
        renderTab={({ child, isActive, onClick }) => (
          <Button onClick={onClick} variant={isActive ? 'primary' : 'neutral'}>
            {child}
          </Button>
        )}
        switcherClassName="gap-4 mb-6"
      >
        <Tabs.Switcher>
          <Tabs.Tab
            onClick={() => {
              setSearchParams({ page: '1', status: 'new' });
              store.clearMenuList();
            }}
          >
            Заказы
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => {
              setSearchParams({ title: 'pickUp' });
              store.clearMenuList();
            }}
          >
            <PlusSVG />
            <span>Сoбой</span>
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => {
              setSearchParams({ title: 'newOrder' });
              store.clearMenuList();
            }}
          >
            <PlusSVG />
            <span>Заказ на стол</span>
          </Tabs.Tab>
        </Tabs.Switcher>
        <Tabs.Panels>
          <Tabs.Panel>
            <OrdersList />
          </Tabs.Panel>
          <Tabs.Panel>
            <PickUpPage />
          </Tabs.Panel>
          <Tabs.Panel>
            <PickUpPage />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </Fragment>
  );
};
