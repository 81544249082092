import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { PaymentTypeEnum } from '@/enums/order.enum';

import { Button } from '@/components/Button';
import { NumberInput } from '@/components/Inputs/NumberInput';
import { usePaymentMethods } from '@/components/OrderInfoModal/PaymentProvider';

// =================================================================

type FormValues = {
  cash: string;
};

// =================================================================

export const PaymentWithCash = () => {
  const { isLoading, onPay, initialValue } = usePaymentMethods();

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: { cash: initialValue.toString() },
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const { cash } = data;

    if (!cash) return;

    const payments = [] as { price: number; payment_type: PaymentTypeEnum }[];
    payments.push({ price: Number(cash), payment_type: PaymentTypeEnum.CASH });

    await onPay(payments);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="cash"
        control={control}
        render={({ field }) => {
          const { onChange, onBlur, ...rest } = field;

          return (
            <NumberInput
              label="Наличные"
              placeholder="Введите сумму"
              className="mb-1"
              thousandSeparator={' '}
              decimalSeparator="."
              autoFocus
              onValueChange={params => {
                onChange(params.value);
              }}
              {...rest}
            />
          );
        }}
      />
      <Button
        type="submit"
        variant="primary"
        disabled={isLoading}
        className="h-auto w-full py-4 text-xl"
      >
        Оплатить
      </Button>
    </form>
  );
};
