import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

// =================================================================

interface TableContentProps {
  children: ReactNode;
  className?: string;
}

// =================================================================

export const TableContent = (props: TableContentProps) => {
  const { children, className } = props;

  return (
    <div
      className={twMerge(
        'grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6',
        className,
      )}
    >
      {children}
    </div>
  );
};
