import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { PickUpApi } from '@/api/domains/pick-up.api';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useNotification } from '@/contexts/NotificationContext';

import { Button } from '@/components/Button';
import { type ModalProps, Modal } from '@/components/Modal';
import { useTabs } from '@/components/Tabs';

import { PickUpConfirmCard } from '@/pages/pick-up/PickUpConfirmCard';
import { usePickUpMethods } from '@/pages/pick-up/PickUpProvider';

// =================================================================

interface PickUpConfirmModalProps
  extends Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> {}

// =================================================================

export const PickUpConfirmModal = (props: PickUpConfirmModalProps) => {
  const { onClose, isOpen, ...rest } = props;

  const { notification } = useNotification();

  const { handleActiveTab } = useTabs();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const { menus, decreaseMenuItem, increaseMenuItem, totalPrice, clearMenuList } =
    usePickUpMethods();

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      onClose();
    };
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const data = menus.map(menu => ({ id: menu.id, count: menu.count }));
      const params: { menus: typeof data; table_id?: number; pick_up?: boolean } = { menus: data };
      if (typeof searchParams.get('tableId') === 'string') {
        params.table_id = Number(searchParams.get('tableId'));
        params.pick_up = false;
      }
      await PickUpApi.create(params);
      clearMenuList();
      onCloseRef.current?.();
      handleActiveTab(0);
      notification({ type: 'success', message: 'Заказ успешно создан!' });
      navigate('/orders?page=1&status=new');
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      notification({ type: 'success', message: error.response.statusText });
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseRef.current}
      canOutsideClickClose={false}
      className="max-w-[736px]"
      {...rest}
    >
      <h3 className="text-2xl font-semibold">Список меню</h3>
      <div className="mt-8">
        <ul>
          {menus.map(menu => (
            <PickUpConfirmCard
              key={menu.id}
              title={menu.title}
              count={menu.count}
              price={menu.price}
              handleIncreament={() => increaseMenuItem(menu)}
              handleDecreament={() => decreaseMenuItem(menu.id)}
            />
          ))}
        </ul>

        <div className="mt-6 flex justify-end">
          <div className="flex items-center gap-6">
            <p className="text-lg font-medium">{thousandSeperator(totalPrice)} сум</p>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={handleSubmit}
              className="text-white"
            >
              Подтвердить
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
