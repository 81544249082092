import { MouseEventHandler, useState } from 'react';

import { OrderApi } from '@/api/domains/order.api';

import { Button } from '@/components/Button';

import { ReactComponent as PrinterSVG } from '@/assets/icons/printer.svg';

export const OrderPrintoutButton = (props: { orderId: number }) => {
  const { orderId } = props;

  const [isPending, setIsPending] = useState(false);

  const printOut: MouseEventHandler<HTMLButtonElement> = async event => {
    event.stopPropagation();

    try {
      setIsPending(true);
      await OrderApi.orderPrintOut(orderId);
    } catch (err) {
      throw err;
    } finally {
      setIsPending(false);
    }
  };

  return (
    <Button variant="success" onClick={printOut} disabled={isPending} className="text-white">
      <PrinterSVG width={28} height={28} />
    </Button>
  );
};
