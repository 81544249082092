import { Fragment, useState, useMemo } from 'react';

import { Button } from '@/components/Button';
import { PaymentProvider } from '@/components/OrderInfoModal/PaymentProvider';
import { PaymentWithCard } from '@/components/OrderInfoModal/PaymentWithCard';
import { PaymentWithCardAndCash } from '@/components/OrderInfoModal/PaymentWithCardAndCash';
import { PaymentWithCash } from '@/components/OrderInfoModal/PaymentWithCash';
import { PaymentWithP2p } from '@/components/OrderInfoModal/PaymentWithP2p';

import { ReactComponent as CreditCardSVG } from '@/assets/icons/credit-card.svg';
import { ReactComponent as MoneySVG } from '@/assets/icons/money.svg';
import { ReactComponent as PaymentP2PSVG } from '@/assets/icons/p2p-payment.svg';
import { ReactComponent as WalletCardSVG } from '@/assets/icons/wallet-card.svg';

// =================================================================

interface PaymentFormProps {
  orderId: string;
  onClose: VoidFunction;
  totalPrice: number;
}

// =================================================================

const views: Record<number, JSX.Element> = {
  0: <PaymentWithCash />,
  1: <PaymentWithCard />,
  2: <PaymentWithP2p />,
  3: <PaymentWithCardAndCash />,
};

// =================================================================

export const PaymentForm = (props: PaymentFormProps) => {
  const { orderId, onClose, totalPrice } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Наличные',
        icon: <MoneySVG width={32} height={32} />,
        onClick: () => {
          setActiveIndex(0);
        },
      },
      {
        label: 'Терминал',
        icon: <CreditCardSVG width={32} height={32} />,
        onClick: () => {
          setActiveIndex(1);
        },
      },
      {
        label: 'P2P оплата',
        icon: <PaymentP2PSVG width={32} height={32} />,
        onClick: () => {
          setActiveIndex(2);
        },
      },
      {
        label: 'Карта и наличные',
        icon: <WalletCardSVG width={32} height={32} />,
        onClick: () => {
          setActiveIndex(3);
        },
      },
    ];
  }, []);

  return (
    <Fragment>
      <h3 className="mb-3 text-xl font-medium">Выберите тип оплаты</h3>
      <ul className="mt-4 grid grid-cols-4 gap-3">
        {tabs.map((tab, index) => (
          <li key={index}>
            <Button
              variant={activeIndex === index ? 'primary' : 'neutral'}
              onClick={tab.onClick}
              className="border-1 join-item h-32 w-full flex-col items-start justify-between gap-0 p-4 text-left text-base"
            >
              {tab.label}
              {tab.icon}
            </Button>
          </li>
        ))}
      </ul>
      <PaymentProvider orderId={orderId} onSuccess={onClose} initialValue={totalPrice}>
        {views[activeIndex]}
      </PaymentProvider>
    </Fragment>
  );
};
