import { Fragment, useState } from 'react';

import { OrderStatusEnum } from '@/enums/order.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useOrderDetails } from '@/pages/order-details/useOrderDetails';

import { Button } from '@/components/Button';
import { OrderDetailsCard } from '@/components/OrderDetailsCard';
import { OrderInfoModal } from '@/components/OrderInfoModal';
import { PageTitle } from '@/components/PageTitle';

import { ReactComponent as CloseSVG } from '@/assets/icons/close.svg';

interface OrderInfoProps {
  orderId: number | null;
  clearOrderId: VoidFunction;
}

export const OrderInfo = (props: OrderInfoProps) => {
  const { orderId, clearOrderId } = props;

  const [selectedOrderId, setSelectedOrderId] = useState('');

  const { order, isLoading } = useOrderDetails({ orderId });

  if (!orderId) return null;

  if (!order && isLoading) return <h2>Loading...</h2>;

  return (
    <Fragment>
      <div className="fixed right-0 top-0 h-screen pr-8 pt-14">
        <div className="w-[420px] rounded-2xl border border-gray p-4 shadow-lg">
          <div className="mb-4 flex items-center justify-between gap-4">
            <PageTitle title={`Заказ № ${order?.number}`} />
            <Button variant="neutral" onClick={clearOrderId}>
              <CloseSVG />
            </Button>
          </div>
          {order && (
            <Fragment>
              <OrderDetailsCard className="border-none p-2">
                <OrderDetailsCard.Title className="mb-2">Информация о столе</OrderDetailsCard.Title>
                <OrderDetailsCard.Price
                  leftText="Номер стола"
                  rightText={order.table_number === null ? 'сoбой' : `№${order.table_number}`}
                  className="mb-2"
                />
                <OrderDetailsCard.Price
                  leftText="Количество гостей"
                  rightText={`${order.client_count} человека`}
                  className="mb-2"
                />
                <OrderDetailsCard.Price
                  leftText="ФИО официанта"
                  rightText={order.waiter.name}
                  className="mb-4"
                />
                {order.menus.length > 0 && (
                  <table className="mb-4 w-full">
                    <thead>
                      <tr>
                        <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">
                          №
                        </td>
                        <td className="border border-black-100 px-1.5 py-1 text-sm font-semibold">
                          Название
                        </td>
                        <td className="border border-black-100 px-1.5 py-1 text-center text-sm font-semibold">
                          К-во
                        </td>
                        <td className="border border-black-100 px-1.5 py-1 text-right text-sm font-semibold">
                          Цена
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {order.menus.map((menu, index) => (
                        <tr key={menu.id} className="text-sm">
                          <td className="border border-black-100 px-1.5 py-0.5">{index + 1}</td>
                          <td className="max-w-36 border border-black-100 px-1.5 py-0.5">
                            {menu.name}
                          </td>
                          <td className="border border-black-100 px-1.5 py-0.5 text-center">
                            {menu.count}
                          </td>
                          <td className="border border-black-100 px-1.5 py-0.5 text-right">
                            {thousandSeperator(menu.price)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </OrderDetailsCard>
              <OrderDetailsCard.Price
                leftText={`Обслуживание ${order.service_percentage}%`}
                rightText={`${thousandSeperator(order.service_price)} сум`}
              />

              <div className="mt-2 flex items-center justify-between">
                <OrderDetailsCard.Title>Итого</OrderDetailsCard.Title>
                <OrderDetailsCard.Title>
                  {thousandSeperator(order.price)} сум
                </OrderDetailsCard.Title>
              </div>
            </Fragment>
          )}

          {order && order.status === OrderStatusEnum.NEW && (
            <Button
              variant="primary"
              className="mt-4 w-full text-lg font-medium text-white"
              onClick={() => setSelectedOrderId(`${orderId}`)}
            >
              Закрыть заказ
            </Button>
          )}
        </div>
      </div>

      <OrderInfoModal
        orderId={orderId.toString()}
        isOpen={Boolean(selectedOrderId)}
        onClose={() => {
          setSelectedOrderId('');
          clearOrderId();
        }}
      />
    </Fragment>
  );
};
