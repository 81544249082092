import { useFavoriteList } from '@/pages/menu/favorite-list/useFavoriteList';
import type { MenuList as MenuListType } from '@/pages/order-details/useOrderDetails';

import { PickMenuCardUI } from '@/pages/pick-up/PickUpCardUI';

// =================================================================

interface FavoriteMenuListProps {
  handleIncreament: (menu: MenuListType) => void;
}

// =================================================================

export const FavoriteMenuList = (props: FavoriteMenuListProps) => {
  const { handleIncreament } = props;

  const { originData } = useFavoriteList();

  if (!originData) return <h3>Loading...</h3>;

  if (Object.keys(originData.list).length === 0) {
    return <h3 className="mt-8 text-2xl">У вас нет списка избранного!</h3>;
  }

  return (
    <div className="mt-8">
      <ul className="grid grid-cols-2 gap-4 xl:grid-cols-3">
        {Object.keys(originData.list).map(key => (
          <li key={key}>
            <PickMenuCardUI
              handleIncreament={() =>
                handleIncreament({
                  id: originData.list[key].menuId!,
                  count: 1,
                  name: originData.list[key].name,
                  price: originData.list[key].price!,
                })
              }
              title={originData.list[key].name}
              price={originData.list[key].price!}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
