import { ColumnDef } from '@tanstack/react-table';
import { memo, useMemo, Fragment, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ExpenseItem } from '@/api/types/expenses.types';
import { TransactionEnum } from '@/enums/expenses.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useExpense } from '@/pages/expenses/useExpense';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';
import { Table } from '@/components/Table';

import { EditExpenseModal } from '@/pages/expenses/EditExpenseModal';
import { ExpenseDeleteModal } from '@/pages/expenses/ExpenseDeleteModal';

import { ReactComponent as EditSVG } from '@/assets/icons/edit.svg';
import { ReactComponent as TrashSVG } from '@/assets/icons/trash.svg';

// =================================================================

export const ExpensesTable = memo(() => {
  const [expenseId, setExpenseId] = useState<string | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, isPlaceholderData, list, pagination, setPagination } = useExpense();

  const columns: ColumnDef<ExpenseItem>[] = useMemo(() => {
    return [
      {
        accessorKey: 'created_at',
        header: 'Дата',
      },
      {
        accessorFn: row => {
          return { transactionType: row.tr_type, waiterId: row.waiter_id };
        },
        header: 'Вид операции',
        cell: props => {
          const { transactionType, waiterId } = props.getValue() as {
            transactionType: TransactionEnum;
            waiterId: number | null;
          };

          if (waiterId) {
            return (
              <Alert
                message="Зарплата"
                type="warning"
                className="flex w-fit min-w-20 items-center justify-center px-2.5 py-0.5 font-medium"
              />
            );
          }

          return transactionType === TransactionEnum.IN ? (
            <Alert
              message="Доход"
              type="success"
              className="flex w-fit min-w-20 items-center justify-center px-2.5 py-0.5 font-medium"
            />
          ) : (
            <Alert
              message="Расход"
              type="error"
              className="flex w-fit min-w-20 items-center justify-center px-2.5 py-0.5 font-medium"
            />
          );
        },
      },
      {
        accessorKey: 'category',
        header: 'Категория',
      },
      {
        accessorKey: 'waiter',
        header: 'Официант',
      },
      {
        accessorKey: 'description',
        header: 'Описание',
        cell: props => {
          const description = props.getValue() as string;
          return (
            <p className="w-72 overflow-hidden text-ellipsis whitespace-nowrap">{description}</p>
          );
        },
      },
      {
        accessorKey: 'price',
        cell: props => {
          return <span>{thousandSeperator(props.getValue() as number)} сум</span>;
        },
        header: 'Сумма',
      },
      {
        accessorFn: row => {
          return { id: row.id, transactionType: row.tr_type };
        },
        cell: props => {
          const { id, transactionType } = props.getValue() as {
            id: string;
            transactionType: TransactionEnum;
          };

          return (
            <div className="flex items-center gap-4">
              <Button
                variant="primary"
                className="flex h-12 min-h-12 w-12 items-center justify-center p-0"
                onClick={() => setExpenseId(id)}
              >
                <TrashSVG />
              </Button>
              <Button
                variant="accent"
                className="flex h-12 min-h-12 w-12 items-center justify-center p-0 text-white"
                onClick={() => {
                  searchParams.set('expenseId', id);
                  searchParams.set('trType', transactionType);
                  setSearchParams(searchParams);
                }}
              >
                <EditSVG />
              </Button>
            </div>
          );
        },
        header: 'Действие',
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!list || isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <Fragment>
      <Table
        columns={columns}
        pageCount={list.last_page + 1}
        data={list.results}
        isLoading={isLoading || isPlaceholderData}
        onPaginationChange={setPagination}
        state={{ pagination }}
      />
      <ExpenseDeleteModal
        key="expense-delete-modal"
        expenseId={expenseId}
        isOpen={Boolean(expenseId)}
        onClose={() => setExpenseId(null)}
      />
      <EditExpenseModal
        key={searchParams.get('expenseId') || 'edit-expense-modal'}
        isOpen={Boolean(searchParams.get('expenseId'))}
        onClose={() => {
          searchParams.delete('expenseId');
          searchParams.delete('trType');
          setSearchParams(searchParams);
        }}
      />
    </Fragment>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  ExpensesTable.displayName = 'ExpensesTable';
}
