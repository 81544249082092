import { twMerge } from 'tailwind-merge';

import { IClassName } from '@/types/common.types';

import { ReactComponent as LogoSVG } from '@/assets/icons/logo.svg';
import { ReactComponent as MiniLogoSVG } from '@/assets/icons/mini-logo.svg';

// =================================================================

interface LogoProps extends IClassName {
  height?: number;
  width?: number;
  isMiniLogo?: boolean;
}

// =================================================================

export const Logo = (props: LogoProps) => {
  const { className, height = 64, width = 64, isMiniLogo = true } = props;

  return (
    <div className={twMerge('flex items-center justify-center py-1 text-center', className)}>
      {isMiniLogo ? (
        <MiniLogoSVG height={height} width={width} />
      ) : (
        <LogoSVG height={height} width={width} />
      )}
    </div>
  );
};
