import { Fragment } from 'react/jsx-runtime';

import { IPropsChildren } from '@/types/common.types';

import type { TabState } from '@/components/Tabs/Tabs';

// =================================================================

interface TabProps extends IPropsChildren, Partial<Pick<TabState, 'renderTab'>> {
  href?: string;
  isActive?: boolean;
  onClick?: VoidFunction;
}

// =================================================================

export const Tab = (props: TabProps) => {
  const { isActive = false, renderTab, children, href, onClick } = props;

  if (typeof renderTab == 'function') {
    return renderTab({ isActive, child: children, href, onClick });
  }

  return <Fragment>{children}</Fragment>;
};
