import { useRef, useState } from 'react';

import { ExpensesApi } from '@/api/domains/expenses.api';
import { ErrorResponse } from '@/api/types/common.types';

import { useExpense } from '@/pages/expenses/useExpense';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Button';
import { Modal, type ModalProps } from '@/components/Modal';

export const ExpenseDeleteModal = (
  props: Omit<ModalProps, 'children' | 'canEscapeKeyClose' | 'canOutsideClickClose'> & {
    expenseId: string | null;
  },
) => {
  const { isOpen, onClose, expenseId, ...rest } = props;

  const [{ errMsg, isLoading }, setFormStatus] = useState({
    errMsg: '',
    isLoading: false,
  });

  const { invalidateExpensesList } = useExpense();

  const onCloseRef = useRef<VoidFunction>();
  if (!onCloseRef.current) {
    onCloseRef.current = () => {
      setFormStatus({ errMsg: '', isLoading: false });
      onClose();
    };
  }

  const handleConfirm = async () => {
    if (!expenseId) return;

    setFormStatus(prev => ({ ...prev, isLoading: true }));
    try {
      await ExpensesApi.deleteExpense(expenseId);
      await invalidateExpensesList();
      onCloseRef.current?.();
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { error: errorMsg } = error.response as ErrorResponse;
      setFormStatus(prev => ({ ...prev, errMsg: errorMsg }));
    } finally {
      setFormStatus(prev => ({ ...prev, isLoading: false }));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseRef.current} {...rest}>
      <h3 className="my-6 text-center text-3xl font-semibold">Вы хотите удалить?</h3>
      {errMsg && <Alert message={errMsg} type="error" />}
      <div className="grid grid-cols-2 gap-4">
        <Button variant="neutral" onClick={onCloseRef.current} className="w-full">
          Нет
        </Button>
        <Button variant="primary" disabled={isLoading} onClick={handleConfirm} className="w-full">
          Да
        </Button>
      </div>
    </Modal>
  );
};
