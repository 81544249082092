import { Fragment } from 'react';

import { OrderStatistics } from '@/pages/cash/OrderStatistics';
import { PaymentStatistics } from '@/pages/cash/PaymentStatistics';

export const CashPage = () => {
  return (
    <Fragment>
      <OrderStatistics />
      <PaymentStatistics />
    </Fragment>
  );
};
