import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useMenuCategory } from '@/pages/menu/useMenuCategory';

import { Button } from '@/components/Button';

export const MenuCategories = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);

  const { isLoading, menuCategory } = useMenuCategory();

  const handleSelect = (id: number | null) => {
    setSelectedCategoryId(id);
    if (id === null) {
      searchParams.delete('categoryId');
      setSearchParams(searchParams);
      return;
    }

    if (searchParams.get('categoryId') !== id.toString()) {
      searchParams.set('categoryId', id.toString());
      setSearchParams(searchParams);
    }
  };

  if (isLoading || !menuCategory) return <h3>Loading...</h3>;

  return (
    <ul className="my-4 flex flex-wrap gap-4">
      <li>
        <Button
          variant={selectedCategoryId === null ? 'primary' : 'neutral'}
          onClick={() => handleSelect(null)}
        >
          Все
        </Button>
      </li>
      {menuCategory.results.map(category => (
        <li key={category.id}>
          <Button
            variant={selectedCategoryId === category.id ? 'primary' : 'neutral'}
            onClick={() => handleSelect(category.id)}
          >
            {category.name}
          </Button>
        </li>
      ))}
    </ul>
  );
};
