import { twMerge } from 'tailwind-merge';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { Button } from '@/components/Button';

import { ReactComponent as MinusSVG } from '@/assets/icons/minus.svg';
import { ReactComponent as PlusSVG } from '@/assets/icons/plus.svg';

// =================================================================

interface PickUpConfirmCardProps {
  title: string;
  price: number;
  count: number;
  handleDecreament: VoidFunction;
  handleIncreament: VoidFunction;
  className?: string;
  maxCount?: number;
}

// =================================================================

export const PickUpConfirmCard = (props: PickUpConfirmCardProps) => {
  const {
    count,
    handleDecreament,
    handleIncreament,
    price,
    title,
    className,
    maxCount = 9999,
  } = props;

  return (
    <li
      className={twMerge(
        'mb-4 flex items-center gap-6 rounded-lg border border-gray-300 px-3 py-2',
        className,
      )}
    >
      <h3 className="w-2/5 text-lg font-medium">{title}</h3>
      <p>{thousandSeperator(price)} сум</p>
      <div className="ml-auto flex items-center gap-4">
        <Button variant="neutral" disabled={count === 0} onClick={handleDecreament}>
          <MinusSVG width={18} height={18} />
        </Button>
        <p className="w-7 text-center text-lg font-medium">{count}</p>
        <Button variant="neutral" disabled={count >= maxCount} onClick={handleIncreament}>
          <PlusSVG width={18} height={18} />
        </Button>
      </div>
    </li>
  );
};
