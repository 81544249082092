import { useCallback, useState, Fragment } from 'react';

import { twMerge } from 'tailwind-merge';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useFavoriteList } from '@/pages/menu/favorite-list/useFavoriteList';

import { Button } from '@/components/Button';

import { MenuSelectModal } from '@/pages/menu/favorite-list/MenuSelectModal';

import { ReactComponent as PlusSVG } from '@/assets/icons/plus.svg';

export const MenuFavoriteList = () => {
  const [orderCount, setOrderCount] = useState<number | null>(null);

  const { isLoading, favoriteList, addTable } = useFavoriteList();

  const onCloseModal = useCallback(() => {
    setOrderCount(null);
  }, []);

  const handleOrderCount = (order: number) => {
    setOrderCount(order);
  };

  if (isLoading) return <h3>Loading...</h3>;

  return (
    <Fragment>
      <ul className="grid grid-cols-5 gap-4">
        {favoriteList.map(list => (
          <li key={list.order}>
            <Button
              variant="neutral"
              onClick={() => handleOrderCount(list.order)}
              className={twMerge('h-24 w-full gap-0 text-lg', list.id && 'justify-start text-left')}
            >
              {list.id ? (
                <Fragment>
                  <p>{list.name}</p>
                  <p className="w-full text-right text-base font-medium text-primary">
                    {thousandSeperator(list.price)} сум
                  </p>
                </Fragment>
              ) : (
                list.order
              )}
            </Button>
          </li>
        ))}
        <li>
          <Button onClick={addTable} variant="neutral" className="h-24 w-full text-2xl">
            <PlusSVG />
          </Button>
        </li>
      </ul>
      <MenuSelectModal
        isOpen={orderCount !== null}
        onClose={onCloseModal}
        orderCount={orderCount}
      />
    </Fragment>
  );
};
