import { useQuery } from '@tanstack/react-query';

import { StatisticsApi } from '@/api/domains/statistics.api';

import { StatisticsQueryKeys } from '@/contexts/QueryContext/query.keys';

export const usePaymentStatistics = (date: string) => {
  const { data } = useQuery({
    queryKey: StatisticsQueryKeys.daily(date),
    queryFn: () => StatisticsApi.dailyStatistics({ date }),
    staleTime: 0,
    enabled: Boolean(date),
  });

  return data;
};
