import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import dayjs, { type Dayjs } from 'dayjs';

import { ExpensesApi } from '@/api/domains/expenses.api';

import { ExpensesQueryKeys } from '@/contexts/QueryContext/query.keys';

type ExpensesForm = {
  date: Dayjs | null;
  price: string;
  description: string;
  category: { label: string; value: number } | null;
  waiter: { label: string; value: number } | null;
};

export const useExpensesDetails = () => {
  const [searchParams] = useSearchParams();

  const key = searchParams.get('expenseId') || '';

  const { data } = useQuery({
    queryKey: ExpensesQueryKeys.expensesDetails(key),
    queryFn: () => ExpensesApi.expensesDetails(key),
    staleTime: Infinity,
    enabled: Boolean(key),
  });

  const expensesDetails: { initialValues: ExpensesForm; id: number } | undefined = useMemo(() => {
    if (!data) return undefined;

    return {
      id: data.id,
      initialValues: {
        price: data.price.toString(),
        date: dayjs(data.created_at.replace(' T', '/'), 'YYYY-MM-DD/HH:mm'),
        description: data.description || '',
        category: data.category_id ? { label: data.category || '', value: data.category_id } : null,
        waiter: data.waiter_id ? { label: data.waiter || '', value: data.waiter_id } : null,
      },
    };
  }, [data]);

  return expensesDetails;
};
