import { Fragment, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { usePaymentStatistics } from '@/pages/cash/PaymentStatistics/usePaymentStatistics';

import { DatePicker } from '@/components/Inputs/DatePicker';
import { PageTitle } from '@/components/PageTitle';

export const PaymentStatistics = () => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());

  const statistics = usePaymentStatistics(startDate.format('DD-MM-YYYY'));

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setStartDate(date);
    }
  };
  const statisticsTableList = useMemo(() => {
    if (!statistics) return [];

    return [
      {
        order: 1,
        label: 'Наличные',
        income: thousandSeperator(statistics.by_cash),
        outcome: 0,
        total: thousandSeperator(statistics.by_cash),
      },
      {
        order: 2,
        label: 'Терминал',
        income: thousandSeperator(statistics.by_card),
        outcome: 0,
        total: thousandSeperator(statistics.by_card),
      },
      {
        order: 3,
        label: 'Перевод  на карту',
        income: thousandSeperator(statistics.by_p2p),
        outcome: 0,
        total: thousandSeperator(statistics.by_p2p),
      },
      {
        order: 4,
        label: 'Итого',
        income: thousandSeperator(statistics.by_p2p + statistics.by_card + statistics.by_cash),
        outcome: 0,
        total: thousandSeperator(statistics.by_p2p + statistics.by_card + statistics.by_cash),
      },
    ];
  }, [statistics]);

  return (
    <Fragment>
      <div className="flex items-center justify-between gap-8">
        <PageTitle title="Статистика по кассу" className="mb-4" />
        <DatePicker value={startDate} onChange={handleDateChange} />
      </div>
      <div className="mb-8 overflow-hidden rounded-lg border bg-white">
        <table className="table table-md w-full whitespace-nowrap">
          <thead className="bg-gray">
            <tr className="text-base">
              <th>№</th>
              <th>Тип</th>
              <th>Приход</th>
              <th>Расход</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {statisticsTableList.map((item, index) => (
              <tr
                key={item.order}
                className={twMerge(
                  index !== statisticsTableList.length - 1 ? 'border-none' : 'font-bold',
                  index % 2 === 0 && 'bg-gray-100',
                )}
              >
                <td className="text-base">{item.order}</td>
                <td className="text-base font-bold">{item.label}</td>
                <td className="text-base">{item.income} сум</td>
                <td className="text-base">{item.outcome} сум</td>
                <td className="text-base">{item.total} сум</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
