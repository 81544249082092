import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useOrderDetails } from '@/pages/order-details/useOrderDetails';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';
import { Tabs } from '@/components/Tabs';

import { FavoriteMenuList } from '@/pages/order-details/FavoriteMenuList';
import { MenuList } from '@/pages/order-details/MenuList';
import { OrderDetailsInfoPanel } from '@/pages/order-details/OrderDetailsInfoPanel';
import { PickUpSearchBar } from '@/pages/pick-up/PickUpSearchBar';

import { ReactComponent as ArrowLeftSVG } from '@/assets/icons/arrow-left.svg';

// =================================================================

export const OrderDetailsPage = () => {
  const navigate = useNavigate();

  const params = useParams() as { id: string };

  const { order, isLoading, menuList, decreaseMenuItem, increaseMenuItem } = useOrderDetails({
    orderId: Number(params.id),
  });

  return (
    <Fragment>
      <div className="relative grid grid-cols-[auto_660px] gap-8">
        <div>
          <div className="mb-6 flex items-center gap-4">
            <Button
              variant="neutral"
              onClick={() => navigate('/orders')}
              className="h-fit min-h-fit p-2"
            >
              <ArrowLeftSVG width={24} height={24} />
            </Button>
            <PageTitle title={order?.table_number ? 'Стол №' + order.table_number : 'Coбой'} />
          </div>
          <Tabs
            renderTab={({ child, isActive, onClick }) => (
              <Button variant={isActive ? 'primary' : 'neutral'} onClick={onClick}>
                {child}
              </Button>
            )}
            switcherClassName="gap-4 mb-4"
          >
            <Tabs.Switcher>
              <Tabs.Tab>Все</Tabs.Tab>
              <Tabs.Tab>Избранное</Tabs.Tab>
            </Tabs.Switcher>
            <Tabs.Panels>
              <Tabs.Panel>
                <PickUpSearchBar />
                <MenuList handleIncreament={increaseMenuItem} />
              </Tabs.Panel>
              <Tabs.Panel>
                <FavoriteMenuList handleIncreament={increaseMenuItem} />
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>
        </div>
      </div>
      <div className="fixed right-0 top-[40px] pr-8">
        <div className="max-h-[90vh] w-[660px] overflow-y-auto rounded-2xl border border-gray p-4 shadow-lg">
          {isLoading && <h2>Loading...</h2>}
          {order && (
            <OrderDetailsInfoPanel
              orderDetails={order}
              menuList={menuList}
              decreaseMenuItem={decreaseMenuItem}
              increaseMenuItem={increaseMenuItem}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
