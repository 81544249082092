import { httpClient } from '@/services/http-client';

export const PickUpApi = {
  create: async ({
    menus,
    table_id,
    pick_up = true,
  }: {
    menus: Array<{ id: number; count: number }>;
    table_id?: number;
    pick_up?: boolean;
  }) => {
    return await httpClient.post('/cashier/order/pick-up/create', {
      menus,
      pick_up,
      table_id,
    });
  },
};
